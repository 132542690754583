import { types } from '../reducers/metaReducer';



// Actions

export const setDefaultMeta = () => ({
   type: types.SET_META,
   payload: {
      description: 'Tenemos el neumático que necesitas. Asesoría personalizada. Variedad en marcas y ofertas de neumáticos para auto y camioneta. Compra en cuotas sin interés.'
   }
});

export const setMeta = (meta) => ({
   type: types.SET_META,
   payload: meta
});
