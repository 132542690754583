import React from 'react';
import PropTypes from 'prop-types';



const LoadingResponse = ({state, isFullView}) => {

   const handleClassName = () => isFullView ? 'fixed-spinner-container fixed' : 'fixed-spinner-container';

   if (!state) {
      return (
         <></>
      );
   }

   return (
      <section className={handleClassName()}>
         <div className='page-spinner'></div>
      </section>
   );
}



LoadingResponse.propTypes = {
   state: PropTypes.bool.isRequired,
   isFullView: PropTypes.bool
}

LoadingResponse.defaultProps = {
   isFullView: true,
}



export default LoadingResponse;