import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';



const Meta = () => {

   const { meta } = useSelector(state => state.meta);

   const [tags, setTags] = useState([]);

   useEffect(() => {
      const data = Object.entries(meta);

      setTags(data);
   }, [meta]);

   return (
      <Helmet>
         {
            tags.map(([key, value], i) => (
               <meta
                  key={'meta-' + i}
                  name={key}
                  content={value}
               />
            ))
         }
      </Helmet>
   )
}



export default Meta;