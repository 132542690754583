import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';



// Actions
import { setDefaultMeta } from '../../actions/meta';



// Components
import LoadingResponse from '../../components/ui/LoadingResponse';
import FontIcon from '../../components/ui/FontIcon';



// Custom hooks
import { useAuthenticated } from '../../hooks/useAuthenticated';
import Meta from '../../components/ui/Meta';
import OfficeLoginForm from '../../components/auth/OfficeLoginForm';



const OfficeLogin = () => {

   const dispatch = useDispatch();

   const { loadingOffice } = useSelector(state => state.auth);

   useAuthenticated(false);

   useEffect(() => {
      document.title = 'Chileneumaticos | Autoservicio | Iniciar sesión';
   }, []);

   useEffect(() => {
      dispatch(setDefaultMeta());
   }, [dispatch]);

   return (
      <>
         <Meta />

         <section className=''>
            <div className='section flex-column mt-0'>
               <div className='auth-container'>
                  <div className='auth-back'>
                     <Link
                        to='/'
                        className='auth-back-btn'
                     >
                        <FontIcon icon='faArrowLeft' />

                        <p className='office-btn'>Volver</p>
                     </Link>
                  </div>

                  <Link
                     to={'/'}
                     className='auth-logo'
                  >
                     <img
                        src={process.env.PUBLIC_URL + '/assets/images/logo-oscuro.svg'}
                        alt='brand logo'
                     />

                  </Link>

                  <h1 className='office-login-title'>AUTOSERVICIO</h1>

                  <div className='auth-form-container'>
                     <h1 className='auth-title'>Iniciar sesión</h1>

                     <OfficeLoginForm />

                     {/* <div className='auth-function'>
                        <p className='light'>¿No tienes una cuenta?</p>

                        <Link to={'/registro'} className='functional blue'>Regístrate</Link>
                     </div> */}

                     {/* <div className='auth-function'>
                        <p className='light'>¿Haz olvidado tu contraseña?</p>

                        <Link to={'/recuperar-clave'} className='functional red'>Recuperar contraseña</Link>
                     </div> */}
                  </div>
               </div>
            </div>

            <LoadingResponse state={loadingOffice} />
         </section>
      </>
   );
}



export default OfficeLogin;