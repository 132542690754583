import { stringCapitalize } from '../helpers/format';

export const types = {
   // Settear error (register)
   SET_ERROR: '[AUTH] set error (register)',
   // Settear error (login)
   SET_AUTH_ERROR: '[AUTH] set auth error',
   // Obtener la información del usuario autenticado
   SET_USER_DATA: '[AUTH] set user data',
   // Obtener la información del usuario autenticado
   SET_OFFICE_DATA: '[AUTH] set office data',
   // Settear estado de loading
   SET_LOADING: '[AUTH] set loading value',
   // Settear un mensaje de exito
   SET_SUCCESS: '[AUTH] set success message'
}



const initialState = {
   // Register errors
   nameError: null,
   lastNameError: null,
   rutError: null,
   emailError: null,
   phoneError: null,
   countryIdError: null,
   typeError: null,
   passError: null,
   pass2Error: null,
   // Login errors
   authError: null,
   // Recovery errors
   tokenError: null,
   // User data
   id: null,
   name: null,
   email: null,
   phone: null,
   rut: null,
   // Office data
   user_office_id: null,
   user_office_name: null,
   office_id: null,
   office: null,
   // Persistent login
   loadingData: true,
   // Persistent office login
   loadingOffice: true,
   // Auth spinner
   loadingAuth: false,
   // Success mesage
   successMessage: null
}



export const authReducer = (state = initialState, action) => {
   switch (action.type) {
      case types.SET_LOADING: {
         const { key, value } = action.payload;

         const keyValue = key.length > 0 ? stringCapitalize(key) : key;

         return {
            ...state,
            [`loading${keyValue}`]: value
         }
      }

      case types.SET_ERROR: {
         const payload = action.payload;

         return {
            ...state,
            [payload.key + 'Error']: payload.error
         }
      }

      case types.SET_SUCCESS: {
         return {
            ...state,
            successMessage: action.payload
         }
      }

      case types.SET_AUTH_ERROR:
         return {
            ...state,
            authError: action.payload
         }

      case types.SET_USER_DATA: {
         const { id, name, email, phone, rut } = action.payload;

         return {
            ...state,
            id, name, email, phone, rut
         }
      }

      case types.SET_OFFICE_DATA: {
         const { user_office_id, user_office_name, office_id, office } = action.payload;

         return {
            ...state,
            user_office_id, user_office_name, office_id, office
         }
      }

      default:
         return state;
   }
}