import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';


// Actions
import { startKeepLoggedOffice } from '../../actions/auth';


// Components
import LoadingPage from '../ui/LoadingPage';
import { SelfManagement } from '../../views/SelfManagment';
import OfficeLogin from '../../views/auth/OfficeLogin';



const PersistentOfficeLog = () => {

   const dispatch = useDispatch();

   const { loadingOffice, office_id } = useSelector(state => state.auth);

   useEffect(() => {
      dispatch(startKeepLoggedOffice());
   }, [dispatch]);

   if (!office_id) {
      return (
         <OfficeLogin />
      )
   }

   if (loadingOffice) {
      return (
         <LoadingPage />
      )
   }

   return (
      <SelfManagement />
   );
}



export default PersistentOfficeLog;