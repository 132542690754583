import { types } from "../reducers/authReducer";
import { request } from "../helpers/request";
import { registerAlert } from "../helpers/auth";



// Actions

export const setRegisterError = (key, error) => ({
   type: types.SET_ERROR,
   payload: { key, error }
});

export const startRegister = (name, lastName, countryId, email, phone, rut, type, password) => {
   return async (dispatch) => {
      dispatch(setLoading('auth', true));

      try {
         const response = await request({
            path: '/usuario/registrar',
            method: 'POST',
            headers: {
               'Content-Type': 'application/json'
            },
            body: {
               datos: [{
                  pais_id: countryId,
                  rut,
                  nombre: `${name} ${lastName}`,
                  email,
                  fono: phone,
                  clave: password,
                  rol: type
               }]
            }
         });

         const { datos } = response.data;

         const token = `${datos['token_type']} ${datos.token}`;

         localStorage.setItem('x-token', token);

         dispatch(startGetData());

         registerAlert(email);
      } catch (error) {
         // console.log(error);

         const errors = error?.response?.data?.errores;

         if (errors['datos.0.rut']) {
            dispatch(setRegisterError('rut', errors['datos.0.rut'][0]));
         }

         if (errors['datos.0.email']) {
            dispatch(setRegisterError('email', errors['datos.0.email'][0]));
         }
      }

      dispatch(setLoading('auth', false));
   }
}

export const setAuthError = (error) => ({
   type: types.SET_AUTH_ERROR,
   payload: error
});

export const setAuthSuccessMessage = (message) => ({
   type: types.SET_SUCCESS,
   payload: message
});

export const startClientLogin = (email, password) => {
   return async (dispatch) => {
      dispatch(setLoading('auth', true));

      try {
         const response = await request({
            path: '/usuario/login',
            method: 'POST',
            headers: {
               'Content-Type': 'application/json'
            },
            body: {
               datos: [{
                  email,
                  clave: password,
                  rol: 1
               }]
            }
         });

         const { datos } = response.data;

         const token = `${datos['token_type']} ${datos.token}`;

         localStorage.setItem('x-token', token);

         dispatch(startGetData());
      } catch (error) {
         console.log(error);

         const errors = error?.response?.data?.errores;

         if (errors) {
            dispatch(setAuthError('Los datos ingresados son inválidos'));
         }
      }

      dispatch(setLoading('auth', false));
   }
}

export const startOfficeLogin = (user, password) => {
   return async (dispatch) => {
      dispatch(setLoading('auth', true));

      try {
         const response = await request({
            path: '/usuarios-sucursales/autenticacion',
            method: 'POST',
            headers: {
               'Content-Type': 'application/json'
            },
            body: {
               usuario: user,
               clave: password,
            }
         });

         // const token = `${datos['token_type']} ${datos.token}`;
         const { token } = response.data;

         localStorage.setItem('y-token', token);

         dispatch(startGetOfficeData());
      } catch (error) {
         console.log(error);

         const errors = error?.response?.data?.errores;

         if (errors) {
            dispatch(setAuthError('Los datos ingresados son inválidos'));
         }
      }

      dispatch(setLoading('auth', false));
   }
}

export const setData = (id, name, email, phone, rut) => ({
   type: types.SET_USER_DATA,
   payload: { id, name, email, phone, rut }
})

export const setOfficeData = (user_office_id, user_office_name, office_id, office) => ({
   type: types.SET_OFFICE_DATA,
   payload: { user_office_id, user_office_name, office_id, office }
})

export const startGetData = () => {
   return async (dispatch) => {
      dispatch(setLoading('data', true));

      try {
         const token = localStorage.getItem('x-token');

         const response = await request({
            path: '/usuario/informacion',
            method: 'POST',
            headers: {
               'Content-Type': 'application/json',
               'Authorization': token
            }
         });

         const { id, nombre, email, fono, rut } = response.data.datos.usuario;

         dispatch(setData(id, nombre, email, fono, rut));
      } catch (error) {
         console.log(error);

         dispatch(startLogout());
      }

      dispatch(setLoading('data', false));
   }
}

export const startGetOfficeData = () => {
   return async (dispatch) => {
      dispatch(setLoading('office', true));

      try {
         const token = localStorage.getItem('y-token');

         const response = await request({
            path: `/usuarios-sucursales/autenticacion-persistente?token=${token}`,
         });
         console.log(response);

         const { id, usuario, sucursal_id, sucursal } = response.data.usuario;

         dispatch(setOfficeData(id, usuario, sucursal_id, sucursal));
      } catch (error) {
         console.log(error);

         dispatch(startLogout());
      }

      dispatch(setLoading('office', false));
   }
}

export const setLoading = (key, value) => ({
   type: types.SET_LOADING,
   payload: { key, value }
});

export const startKeepLogged = () => {
   return async (dispatch, getState) => {
      try {
         const token = localStorage.getItem('x-token');

         const { name } = getState().auth;

         (token && !name) ? dispatch(startGetData()) : dispatch(setLoading('data', false));
      } catch (error) {
         console.log(error);
      }
   }
}

export const startKeepLoggedOffice = () => {
   return async (dispatch, getState) => {
      try {
         const token = localStorage.getItem('y-token');

         const { user_office_name } = getState().auth;

         (token && !user_office_name) ? dispatch(startGetOfficeData()) : dispatch(setLoading('office', false));
      } catch (error) {
         console.log(error);
      }
   }
}

export const startLogout = () => {
   return (dispatch) => {
      localStorage.removeItem('x-token');

      dispatch(setData(null, null, null, null, null));
   }
}

export const startLogoutOffice = () => {
   return (dispatch) => {
      localStorage.removeItem('y-token');

      dispatch(setOfficeData(null, null, null, null));
   }
}

export const startPasswordRecovery = (email) => {
   return async (dispatch) => {
      dispatch(setLoading('auth', true));

      try {
         await request({
            location: '/common/v1',
            path: '/usuario/recuperar-clave',
            method: 'POST',
            headers: {
               'Content-Type': 'application/json'
            },
            body: {
               email,
               fuente: 'api'
            }
         });
      } catch (error) {
         console.log(error);

         const errors = error?.response?.data?.errores;
      }

      dispatch(setAuthSuccessMessage(('Hemos enviado un correo con los pasos a seguir para recuperar tu contraseña')));

      dispatch(setLoading('auth', false));
   }
}

export const startPasswordReset = (token, email, password) => {
   return async (dispatch) => {
      dispatch(setLoading('auth', true));

      try {
         await request({
            location: '/common/v1',
            path: '/usuario/cambiar-clave',
            method: 'POST',
            headers: {
               'Content-Type': 'application/json'
            },
            body: {
               token,
               email,
               clave: password,
            }
         });

         dispatch(setAuthSuccessMessage(('Su contraseña ha sido reestablecida satisfactoriamente')));
         dispatch(setAuthError(null));
      } catch (error) {
         console.log(error);

         const errors = error?.response?.data?.errores;

         dispatch(setAuthError('El enlace para recuperar su contraseña ha vencido o ya fue usado, solicite uno nuevo'));
         dispatch(setAuthSuccessMessage((null)));
      }

      dispatch(setLoading('auth', false));
   }
}