export const types = {
   SET_META: '[META] set metadata',
}



const initialState = {
   meta: {}
}



export const metaReducer = (state = initialState, action) => {
   switch (action.type) {
      case types.SET_META:
         return {
            ...state,
            meta: action.payload
         }

      default:
         return state;
   }
}