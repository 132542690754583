import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';



// Actions
import { setAuthError, startOfficeLogin } from '../../actions/auth';



const OfficeLoginForm = () => {

   const dispatch = useDispatch();

   const { authError } = useSelector(state => state.auth);

   const [user, setUser] = useState('');
   const [password, setPassword] = useState('');

   useEffect(() => {
      return () => {
         setUser('');
         setPassword('');

         dispatch(setAuthError(null));
      }
   }, [dispatch]);
   
   const handleForm = (e) => {
      e.preventDefault();

      dispatch(setAuthError(null));

      dispatch(startOfficeLogin(user, password));
   }

   const handleInputChange = (e, setValue) => setValue(e.target.value);

   return (
      <form
         className='auth-form'
         onSubmit={handleForm}
      >
         { authError && (<p className='auth-error'>{authError}</p>) }

         <input
            type='user'
            placeholder='USUARIO'
            autoComplete='off'
            name='user'
            value={user}
            onChange={(e) => handleInputChange(e, setUser)}
         />

         <input
            type='password'
            placeholder='CONTRASEÑA'
            autoComplete='off'
            name='password'
            value={password}
            onChange={(e) => handleInputChange(e, setPassword)}
         />

         <button
            type='submit'
         >INGRESAR</button>
      </form>
   );
}



export default OfficeLoginForm;